import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import AuthModal from './AuthModal';

export const NavBar = () => {

  const [showForm, setShowForm] = useState(false);

  const hideForm = () => {
    setShowForm(false);
  }

  return (
    <Navbar sticky="top"  expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home" style={{
            fontFamily: "serif",
            color: "#2596be"
        }}><b>CVWand</b></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <Nav.Link href="#faq">FAQ</Nav.Link>
            <Nav.Link href="#support">Support</Nav.Link>
            <Nav.Link href="#" onClick={() => {
              setShowForm(true);
            }}>Login</Nav.Link>
            <Nav.Link href="#">Sign Up</Nav.Link>{" "}
            <Nav.Link href="#privacy"><span style={{fontStyle: "italic", fontSize: "11px"}}>Privacy</span></Nav.Link>
            <Nav.Link href="#terms"><span style={{fontStyle: "italic", fontSize: "11px"}}>Terms</span></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <AuthModal showModal={showForm} hideModal={hideForm} />

    </Navbar>
  );
}