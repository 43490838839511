import React from 'react';
import { NavBar } from "./Nav";
import {BrowserRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HomeSplash } from './HomeSplash';
import { Features } from './Features';
import { Pricing } from './Pricing';
import { FAQ } from './FAQ';
import { Support } from './Support';

const App: React.FC = () => {

   return  <div className="App">
      <NavBar />
      <HomeSplash />
      <Features />
      <Pricing/>
      <FAQ />
      <Support />
    </div>
};

export default App;