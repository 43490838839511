import React from "react";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import AIWrite from "./undraw_typewriter_re_u9i2.svg"
import ATSImage from "./undraw_futuristic_interface_re_0cm6.svg";
import MatchJob from "./undraw_ideas_re_7twj.svg"
import RemoteImg from "./undraw_working_from_anywhere_re_9obt.svg"
import LinkedInImg from "./undraw_happy_announcement_re_tsm0.svg"
import RecruiterImg from "./undraw_working_re_ddwy.svg"

export const Features = () => {
    return <Container id="features" style={{ textAlign: 'center', backgroundColor: '#f8f9fa' }} fluid>
        <Row style={{textAlign: "center", paddingTop: "55px", color: '#525252', fontFamily: 'Georgia, serif',}}>
            <h2>Explore Our Features</h2>
        </Row>
        <Row lg={3} md={2} sm={1}>
            <Col>
                <Container style={{ padding: "50px" }}>
                    <Card style={{backgroundColor: "#f8f9fa", border: "none"}}>
                        <Card.Img variant="top" src={AIWrite} style={{maxHeight: "180px"}}  />
                        <Card.Body>
                            <Card.Title style={{ color: "#2596be", textAlign: "left" }}>AI Rewrites</Card.Title>
                            <hr/>
                            <Card.Text style={{ color: "grey", textAlign: "left" }}>
                                Revamp Your Resume with AI Precision
                                Our advanced AI-powered tool rewrites your resume for you, ensuring it's professional, concise, and tailored to industry standards. Get personalized suggestions that highlight your skills and experience, giving your resume the edge it needs to stand out.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </Col>

            <Col>
                <Container style={{ padding: "50px" }}>
                <Card style={{backgroundColor: "#f8f9fa", border: "none"}}>
                <Card.Img variant="top" src={ATSImage} style={{maxHeight: "180px"}}  />
                        <Card.Body>
                            <Card.Title style={{ color: "#2596be", textAlign: "left" }}>ATS Ready Resume</Card.Title>
                            <hr/>
                            <Card.Text style={{ color: "grey", textAlign: "left" }}>
                            Optimize Your Resume for Applicant Tracking Systems
Create resumes that pass through Applicant Tracking Systems (ATS) effortlessly. This feature formats your resume in an ATS-friendly manner, ensuring that it reaches recruiters without getting filtered out.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </Col>

            <Col>
                <Container style={{ padding: "50px" }}>
                <Card style={{backgroundColor: "#f8f9fa", border: "none"}}>
                <Card.Img variant="top" src={MatchJob} style={{maxHeight: "180px"}} />
                        <Card.Body>
                            <Card.Title style={{ color: "#2596be", textAlign: "left" }}>Match to Listing</Card.Title>
                            <hr/>
                            <Card.Text style={{ color: "grey", textAlign: "left" }}>
                            Tailor Your Resume to Job Listings in Seconds
                            Automatically match your resume to specific job descriptions, ensuring your application highlights the most relevant skills and qualifications. This feature saves you time and maximizes your chances of landing the job.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </Col>
            
        </Row>
        <Row lg={3} md={2} sm={1}>
            <Col>
                <Container style={{ padding: "50px" }}>
                    <Card style={{backgroundColor: "#f8f9fa", border: "none"}}>
                        <Card.Img variant="top" src={RemoteImg} style={{maxHeight: "180px"}}  />
                        <Card.Body>
                            <Card.Title style={{ color: "#2596be", textAlign: "left" }}>Remote Jobs from CV</Card.Title>
                            <hr/>
                            <Card.Text style={{ color: "grey", textAlign: "left" }}>
                                We Analyse Your CV for Skills and Roles Thoroughly and Suggest Remote Job Openings from Across the Globe. Discover the Perfect Work-From-Anywhere Roles Quickly and Effortlessly, Bringing Your Next Remote Opportunity Just a Scan Away!
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </Col>

            <Col>
                <Container style={{ padding: "50px" }}>
                <Card style={{backgroundColor: "#f8f9fa", border: "none"}}>
                <Card.Img variant="top" src={LinkedInImg} style={{maxHeight: "180px"}}  />
                        <Card.Body>
                            <Card.Title style={{ color: "#2596be", textAlign: "left" }}>LinkedIn Analysis</Card.Title>
                            <hr/>
                            <Card.Text style={{ color: "grey", textAlign: "left" }}>
                            Enhance Your Professional Profile with LinkedIn Insights
Get actionable insights on how your LinkedIn profile compares with industry standards. Receive recommendations to boost visibility, improve connections, and ensure consistency with your resume.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </Col>

            <Col>
                <Container style={{ padding: "50px" }}>
                <Card style={{backgroundColor: "#f8f9fa", border: "none"}}>
                <Card.Img variant="top" src={RecruiterImg} style={{maxHeight: "180px"}} />
                        <Card.Body>
                            <Card.Title style={{ color: "#2596be", textAlign: "left" }}>For Recruiters</Card.Title>
                            <hr/>
                            <Card.Text style={{ color: "grey", textAlign: "left" }}>
                            Comprehensive Recruiting Tools (Coming Soon)
Coming soon, our suite of tools for recruiters will offer resume shortlisting, candidate analysis, and AI-powered job description generation. Streamline your recruitment process and find the best talent faster with our cutting-edge solutions.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </Col>
            
        </Row>
    </Container>
}