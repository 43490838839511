import React from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import HomeBg from "./undraw_co-working_re_w93t.svg";
import { CheckCircleFill, CupHot, PersonCheck } from "react-bootstrap-icons";

export const Pricing = () => {
    return <div id="pricing"> <Container  fluid style={{marginTop: "100px", backgroundColor: "white"}}>
        <div style={{
            fontFamily: 'Georgia, serif',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        }}>
            <div><h1>Affordable Pricing for Everyone</h1>
            <hr />
            <h6>
                <span style={{color: "grey", fontFamily: "Aerial, sans-serif"}}>
                Pay only for what you use. We Cut Down Huge Upfront Subscription Costs!<br/>
               Get <Badge bg="secondary">+5</Badge> free credits on signup!
                </span>
            </h6>
            <br />
            <Container style={{fontFamily: "serif"}}>
                <Row sm="1" md="2" lg="3" xs={1}>
                    <Col>
                    <Card style={{height: "290px", border: "none", backgroundColor: "#F8F9FA", borderRadius: "10px"}}>
                        <Card.Body style={{textAlign: "left"}}>
                            <h1 style={{color: "#2596BE"}}>$3.00 </h1>
                            <span style={{fontSize: "14px", color: "grey"}}>That's Only <u>20¢/Resume!</u></span><br />
                            <br />
                            <h5>What you get:</h5>
                            <hr />
                            <p style={{fontFamily: "sans-serif", fontSize: "0.9em"}}><CheckCircleFill/> 15 Credits</p>
                            <p style={{fontFamily: "sans-serif", fontSize: "0.9em"}}><CheckCircleFill/> Scans Resume upto 15 Times!</p>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col>
                    <Card style={{height: "290px", border: "none", backgroundColor: "#F8F9FA", borderRadius: "10px"}}>
                        <Card.Body style={{textAlign: "left"}}>
                            <h1 style={{color: "#2596BE"}}>$8.00 </h1>
                            <span style={{fontSize: "14px", color: "grey"}}>Power pack of <u>15¢/Resume!</u></span><br />
                            <br />
                            <h5>What you get:</h5>
                            <hr />
                            <p style={{fontFamily: "sans-serif", fontSize: "0.9em"}}><CheckCircleFill/> 50 Credits</p>
                            <p style={{fontFamily: "sans-serif", fontSize: "0.9em"}}><CheckCircleFill/> Scans Resume upto 50 Times!</p>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col>
                    <Card style={{height: "290px", border: "none", backgroundColor: "#F8F9FA", borderRadius: "10px"}}>
                        <Card.Body style={{textAlign: "left"}}>
                            <h1 style={{color: "#2596BE"}}>Top-up </h1>
                            <h5>Thereafter:</h5>
                            <p style={{fontFamily: "sans-serif", fontSize: "1em", color:"grey"}}>$1 / 10 Credits!</p>
                            <br />
                            <p style={{fontFamily: "sans-serif", fontSize: "0.9em", color:"grey"}}>Easily top up as per requirement to your available credits.</p>
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
            </Container>
            
            <br />
            <hr />
        <Button variant="warning" size="lg"><PersonCheck style={{marginTop: "-7px"}} /> Create Account</Button>

            </div>
        </div>

  </Container></div>
}