import React from "react";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import HomeBg from "./undraw_co-working_re_w93t.svg";

export const HomeSplash = () => {
    return <Container id="home">
    <Row>
      
      <Col style={{
        textAlign: 'center',
        height: '70vh',
      }}>

        <div style={{
            fontFamily: 'Georgia, serif',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
        }}>
            <div><h1><span style={{color: '#2596be', }}>CVWand</span> <span style={{backgroundColor: '#e8f7fc', padding: '3px'}}>Enhances</span> Your Job Seeking Process!</h1>
            <hr />
            
            <Button style={{marginBottom: "12px"}} variant="outline-warning">Create Account</Button>
            <br />
            <h6>
                <span style={{color: "#24748f"}}>
                Get Sophisticated AI Rewrites, ATS Ready Resume,<br /> Match to Listing & More! <br /><br />
                <Badge bg="warning">+3 Free Credit</Badge>
                </span>
            </h6>

            <></>
            </div>
        </div>
      </Col>
      <Col style={{
        height: '80vh'
      }}>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh'
        }}>
            <img style={{
                maxWidth: "71%"
            }} src={HomeBg} />
        </div>
      </Col>

    </Row>
  </Container>
}