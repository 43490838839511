import React from "react";
import { Accordion, Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import HomeBg from "./undraw_co-working_re_w93t.svg";
import { CheckCircleFill, CupHot, PersonCheck, QuestionCircle } from "react-bootstrap-icons";

export const FAQ = () => {
    return <Container fluid id="faq" style={{marginTop: "140px", marginBottom: "140px", paddingLeft: "20%", paddingRight: "20%", height: "100%"}}>
        <div style={{
            fontFamily: 'Georgia, serif',
            textAlign: "center",
            height: '100%',
        }}>
            <br />
            <div><h1>FAQ</h1>

            </div>

            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header><QuestionCircle style={{marginRight: "10px"}}/>Data Safety & Encryption</Accordion.Header>
        <Accordion.Body style={{textAlign: "left"}}>
          Your data is fully encrypted at rest using AES256 encryption standard. Only you have the access to generated reports and metrics.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><QuestionCircle style={{marginRight: "10px"}}/>How does credits and usage work?</Accordion.Header>
        <Accordion.Body style={{textAlign: "left"}}>
          Credits are used to spend on resume analysis tasks like ATS checks, resume rewrites, resume insights, etc.
          Depending on the resume size, the credits utilised may vary. For an 800 words resume, expect a credit to be used.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><QuestionCircle style={{marginRight: "10px"}}/>How to view Remote Job Listings?</Accordion.Header>
        <Accordion.Body style={{textAlign: "left"}}>
          Based on your Resume profile, we suggest similar job listings from <a href="https://jobicy.com/" target="_blank">Jobicy</a> Platform
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </div>

  </Container>
}