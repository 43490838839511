import React from "react";
import { Accordion, Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import HomeBg from "./undraw_co-working_re_w93t.svg";
import { CheckCircleFill, CupHot, Mailbox2Flag, PersonCheck, QuestionCircle } from "react-bootstrap-icons";
import SupportImg from "./undraw_working_remotely_re_6b3a.svg";
export const Support = () => {
    return <Container fluid id="support" style={{paddingLeft: "20%", paddingRight: "20%", backgroundColor: "#f8f9fa"}}>
        <div style={{
            fontFamily: 'Georgia, serif',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}>
            <br />
            <div style={{textAlign: "center"}}>
              <img style={{maxWidth: "300px", margin: "20px 20px"}} src={SupportImg} />
              <h1><Mailbox2Flag style={{marginTop: "-4px"}}/> <span style={{color: "grey"}}><a style={{color: "grey"}} href="mailto:queries@cvwand.com">queries@cvwand.com</a></span></h1>
            <hr />
            <h4 style={{color: "grey", fontFamily: "sans-serif"}}>Avg Response Duration: 24h</h4>
            <Button variant="secondary"><a style={{color: "white"}} href="https://cvwand.tawk.help/" target="_blank">Create a Ticket</a></Button>
            </div>
            
        </div>

  </Container>
}