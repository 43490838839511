import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { createClient, Session } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'

const supabase = createClient('https://fycqmxopvplhnwrfbnbp.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ5Y3FteG9wdnBsaG53cmZibmJwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY1MjEwNDksImV4cCI6MjA0MjA5NzA0OX0.mveAIKfG2IuhqSpXsCawIpbGwPMORMau7NX-KxqhzrA')

const AuthModal = (props: {showModal: boolean, hideModal: () => void}) => {

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.showModal);
  }, [props.showModal])

  const handleClose = () => {
    setShow(false);
    props.hideModal()
  };

  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          {
            !session &&<Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />
          }
          {
            session && "You're already logged in!"
          }
          {
            session && <Button onClick={async () => {
              const { error } = await supabase.auth.signOut()

            }}>Logout</Button>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuthModal;